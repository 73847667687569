import React from "react"

export const AboutText = () => (
    <p>
        В 1999г. закончил Одесский государственный медицинский университет по специальности
        хирургия.
        <br/>
        1999-2001г. - интернатура по общей хирургии на базе разнопрофильных хирургических
        отделений центральной черноморской бассейновой клинической больницы и областной
        клинической больниц.
        <br/>
        <br/>

        2001-2004г. - хирург отделения общей хирургии и гнойно-септической хирургии ЧЦБКБ (
        больницы водников).

        <br/>
        <br/>
        2004 - 2007г. - работа за рубежом по контракту врачем-хирургом ( Малайзия, Индонезия,
        Папуа-Новая Гвинея, Катар(Доха), Канада (Монреаль))
        С 2008г. - врач-хирург хирургического отделения центра хирургии печени и
        поджелудочной железы на база одесского областного клинического медицинского центра.
        Работа на кафедре хирургии №2 одесского медуниверситета.
            <br/>
            <br/>

        В 2012г. прошёл курсы усовершенствования &#39;&#39; Лапароскопическая хирургия&#39;&#39; на кафедре
        хирургических болезней с последипломной подготовкой Одесского медицинского
        университета.
            <br/>
            <br/>

        В 2006г. прошёл курсы усовершенствования &#39;&#39; Основы пластической реконструктивной и
        эстетической хирургии&#39;&#39; на кафедре комбустиологии, пластической хирургии и урологии
        Донецкого государственного медицинского университета .
            <br/>
            <br/>

        В 2007г. завершил информационно-методический тренинг по инъекционной объёмно-
        контурной пластике препаратами “Juvederm”, “Restyline”, “Surgiderm”, “Radiesse”,
        “Biopolimer” и препарата на основе ботулотоксина типа А- “BOTOX”.
            <br/>
            <br/>

        С 2009г. по настоящий момент работает врачём-хирургом, специалистом по пластической
        и реконструктивной хирургии на базе клинической больницы высшей категории г.Южного.
            <br/>
            <br/>

        Является постоянным участником научно-практических конференций, конгрессов,
        международных семинаров и съездов по пластической, эстетической и реконструктивной
        хирургии и объёмно-контурной пластике, проводимых на территории Украины, ближнего
        и дальнего зарубежья.
    </p>
)