import img1 from "./100.png";
import img2 from "./101.png";
import img3 from "./102.png";
import img4 from "./104.png";
import img5 from "./105.png";
import img6 from "./208.png";
import img7 from "./214.png";
import img8 from "./214.png";
import img9 from "./299.png";
import img10 from "./306.png";
import img11 from "./309.png";
import img12 from "./329.png";

export const after = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12
]